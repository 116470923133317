<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-3">
          Perf. Improvement Plan
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>

    <template>
      <div class="m-3">
        <PreliminaryDetails
          :employee-data="preliminaryData.employeeData"
          :line-manager="preliminaryData.lineManager"
          :performance="preliminaryData.performance"
          :previous-interviews="preliminaryData.previousInterviews"
          :pip-initiation-date="preliminaryData.pipInitiationDate"
          :pip-duration="preliminaryData.pipDuration"
          :interview-days="preliminaryData.previousDays"
          :review-sessions="preliminaryData.reviewSessions"
        />
      </div>
    </template>
    <template>
      <div class="w-full flex justify-center mt-5">
        <div class="w-6/12 border-b border-romanSilver" />
      </div>
    </template>
    <template>
      <div class="m-3 mt-10 flex flex-col gap-5">
        <div class="relative overflow-x-auto">
          <PerformanceSummary :data="summaryOut" />
        </div>
        <template>
          <div class="flex justify-between items-center my-3">
            <div
              class="border border-romanSilver rounded-md flex justify-between items-center divide-x divide-romanSilver p-2 gap-2"
            >
              <div class="flex text-xs font-black leading-5 uppercase gap-2">
                <span class="text-romanSilver">Success Criteria:</span>
                <span class="text-darkPurple"
                  >Min {{ summaryOut.successCriteria }}%</span
                >
              </div>
              <div class="flex justify-between items-center gap-2 pl-2">
                <div class="flex flex-col items-start justify-start">
                  <span class="font-black text-xs uppercase text-romanSilver">
                    Final Score
                  </span>
                  <span class="font-normal text-xs uppercase text-romanSilver">
                    (Weighted Average)
                  </span>
                </div>
                <div class="flex flex-col justify-center items-start -my-2">
                  <div class="flex justify-start items-center gap-1 -my-1">
                    <span class="font-semibold text-sm text-jet">
                      3.92 of 4.00 -
                    </span>
                    <span class="text-desire text-lg font-bold"> 98% </span>
                  </div>
                  <span
                    class="font-semibold text-10px uppercase text-darkPurple"
                  >
                    Total Rating = 15 of 16
                  </span>
                </div>
              </div>
            </div>
            <div
              class="flex justify-between items-center rounded-md p-4 gap-2"
              :class="
                summaryOut.outcome === 'Unsuccessful Outcome!'
                  ? `bg-desire`
                  : `bg-mediumSeaGreen`
              "
            >
              <Icon
                icon-name="icon-flag"
                size="xs"
                class="text-ghostWhite font-bold"
              />
              <span class="text-ghostWhite font-bold text-lg leading-5">
                {{ summaryOut.outcome }}
              </span>
            </div>
          </div>
        </template>
        <EmployeeEngagement
          :is-line-manager="true"
          :header="commentSection.header"
          :title="commentSection.title"
          :is-further-action="true"
          :set-options="pipOptions"
          :data="commentSection.commentData"
          @set="receiveEngagement($event)"
        />
        <template>
          <SignaturesComments
            header="Signatures and Comments"
            :items="section6Data"
            @set="setOutcomePayload($event)"
          />
        </template>
      </div>
    </template>
    <template>
      <div class="flex justify-start items-center m-3 mt-32 -mb-20">
        <div
          class="flex justify-evenly items-center gap-5 text-base font-semibold leading-5"
        >
          <Button
            class="bg-dynamicBackBtn text-white px-2 w-48 h-11 flex justify-center"
            @click="setCloseout"
            :disabled="disableBtn"
          >
            Update Changes
          </Button>
          <Button class="text-darkPurple"> Cancel </Button>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Button from "@/components/Button";
import PreliminaryDetails from "./Components/PreliminaryDetails";
import EmployeeEngagement from "./Components/EmployeeEngagement";
import PerformanceSummary from "./Components/PerformanceSummary";
import SignaturesComments from "./Components/SignaturesComments";

export default {
  name: "ReviewSession",
  components: {
    BackButton,
    Breadcrumb,
    Button,
    PreliminaryDetails,
    PerformanceSummary,
    EmployeeEngagement,
    SignaturesComments,
  },
  data() {
    return {
      loading: true,
      disableBtn: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance Improvement",
          href: "performance-improvement",
          id: "PerformanceImprovement",
        },
        {
          disabled: false,
          text: "Improvement FY2021",
          href: "performance-improvement-FY2021",
          id: "PerformanceImprovementFY2021",
        },
        {
          disabled: false,
          text: "Performance Improvement Plan",
          href: "performance-improvement-plan",
          id: "PerformanceImprovementPlan",
        },
      ],
      pipSignatureDetails: [],
      preliminaryData: {
        employeeData: {
          avatar: "",
          name: "",
          id: "",
          designation: "",
          department: "",
          dateEmployed: "",
          supervisoryFunction: "",
        },
        lineManager: {
          avatar: "",
          name: "",
          designation: "",
        },
        performance: [],
        previousInterviews: "",
        pipInitiationDate: "",
        pipDuration: { from: "", to: "" },
        reviewSessions: [],
      },
      section6Data: [],
      summaryStructure: {
        orgId: "",
        employeePipId: "",
        actionId: "",
        recommendation: "",
        others: "",
        signatory: {
          comments: "",
          date: "",
          signature: "",
          signatories: [],
        },
        notify: true,
      },
      summaryOut: {
        items: [],
        footer: { value: "", score: "" },
        outcome: "",
        successCriteria: "",
      },
      commentSection: {
        header: "Further Actions and Recommendations",
        recommendation: "Terminate Employee",
        commentData: {},
      },
      signatureComments: [],
      pipOptions: [],
      pipSignatures: [],
    };
  },

  methods: {
    getEmployeePerformance() {
      this.$_getEmployeePreliminary(this.$route.params.id).then((response) => {
        const { userDetails } = response.data;

        this.preliminaryData.employeeData.avatar = userDetails.photo;
        this.preliminaryData.employeeData.name = `${userDetails.fname} ${userDetails.lname}`;
        this.preliminaryData.employeeData.id = "---";
        this.preliminaryData.employeeData.designation = userDetails.designation;
        this.preliminaryData.employeeData.dateEmployed = "";
        this.preliminaryData.employeeData.department = userDetails.function;
        this.preliminaryData.employeeData.supervisoryFunction =
          userDetails.lineManager.function;
        this.preliminaryData.lineManager.avatar = userDetails.lineManager.photo;
        this.preliminaryData.lineManager.name = `${userDetails.lineManager.fname} ${userDetails.lineManager.lname}`;
        this.preliminaryData.lineManager.designation =
          userDetails.lineManager.designation;

        this.managerId = userDetails.lineManager.managerId;
        this.userId = userDetails.userId;

        const { performanceScore } = response.data;

        performanceScore.map((score, index) => {
          this.preliminaryData.performance.push({
            status: this.setStatus(index, score.score),
            year: `${score.date}`,
            value: score.score,
          });
          return {};
        });
      });
    },

    setStatus(position, score) {
      let status;

      if (position === 0) {
        status = "default";
      }

      if (position > 0) {
        const prevScore = this.preliminaryData.performance[position - 1].value;
        const currScore = score;

        if (currScore > prevScore) {
          status = "increasing";
        } else {
          status = "decreasing";
        }
      }

      return status;
    },

    getPIPSignatures() {
      this.$_getPIPSignatures(this.$route.query.pip)
        .then((response) => {
          this.pipSignatures = response.data.data.closeoutSignatures;
          this.getSignatories();
        })
        .catch((err) => {
          this.getSignatories();
          return err;
        });
    },

    setSignatureDetails(userId, type) {
      let signature;
      let comments;
      let date;
      let idValue;

      if (type === "signature") {
        if (this.pipSignatures) {
          this.pipSignatures.filter((signatory) => {
            if (signatory.userId === userId) {
              signature = this.$getInitials(
                `${signatory.fname} ${signatory.lname}`
              );
            }
            return {};
          });
        }
        return signature || "";
      }

      if (type === "comments") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              comments = signatory.comments;
            }
            return {};
          });
        }
        return comments || "";
      }

      if (type === "date") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              date = signatory.date;
            }
            return {};
          });
        }
        return date || "";
      }

      if (type === "id") {
        if (this.pipSignatureDetails) {
          this.pipSignatureDetails.filter((signatory) => {
            if (signatory.signatoryId === userId) {
              idValue = signatory.id;
            }
            return {};
          });
        }
        return idValue || "";
      }

      return "";
    },

    getSignatories() {
      this.$_getPIPSignatories(
        this.$route.params.templateId,
        "?signatory=pip"
      ).then((response) => {
        const signatories = response.data.data;
        this.loading = false;

        signatories.filter((item) => {
          if (item.userId !== this.managerId) {
            this.section6Data.push({
              id: this.setSignatureDetails(item.userId, "id"),
              photo: item.photo,
              name: `${item.fname} ${item.lname}`,
              position: item.designation,
              signature: this.setSignatureDetails(item.userId, "signature"),
              userId: item.userId,
              comments: this.setSignatureDetails(item.userId, "comments"),
              date: this.setSignatureDetails(item.userId, "date"),
            });
          }
          return {};
        });

        this.section6Data.push({
          id: this.setSignatureDetails(this.managerId, "id"),
          photo: this.preliminaryData.lineManager.avatar,
          name: this.preliminaryData.lineManager.name,
          position: this.preliminaryData.lineManager.designation,
          signature: this.setSignatureDetails(this.managerId, "signature"),
          userId: this.managerId,
          comments: this.setSignatureDetails(this.managerId, "comments"),
          date: this.setSignatureDetails(this.managerId, "date"),
        });
      });
    },

    getEmployeePIP() {
      this.$_getEmployeePIP(this.$route.params.id).then((response) => {
        const details = response.data.pipDetails;
        this.preliminaryData.previousInterviews =
          details.hasDonePreviousInterview;
        this.preliminaryData.pipInitiationDate = this.resetDate(
          details.pipInitiationDate
        );
        this.preliminaryData.pipStartDate = this.resetDate(
          details.pipStartDate
        );
        this.preliminaryData.pipEndDate = this.resetDate(details.pipEndDate);
        this.preliminaryData.pipDuration = {
          from: this.resetDate(details.pipStartDate),
          to: this.resetDate(details.pipEndDate),
        };
        this.preliminaryData.previousDays = this.resetDate(
          details.previousInterviewDate
        );

        details.reviewSessionDate.map((session) => {
          // eslint-disable-next-line no-param-reassign
          session.date = this.resetDate(session.date);
          return {};
        });

        this.preliminaryData.reviewSessions = details.reviewSessionDate;
      });
    },

    formatDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    setCriteria(kpi) {
      const criterias = [];

      kpi.map((item) => {
        criterias.push(item.criteria);

        return {};
      });
      return criterias;
    },

    getEmployeeSummary() {
      this.$_getEmployeeSummary(this.$route.params.id).then((result) => {
        const sessions = result.data.reviewsSessions;
        let sum = 0;

        sessions.forEach((session) => {
          this.summaryOut.employeePipId = session.employeePipId;
          this.summaryStructure.employeePipId = session.employeePipId;

          session.review_scores.forEach((data) => {
            this.summaryOut.items.push({
              objectives: data.pip_task.objective,
              criteria: this.setCriteria(data.pip_task.criterias),
              weight: data.pip_task.weight,
              session1: { rating: data.rating, score: data.score },
            });

            sum += data.pip_task.weight;

            this.summaryOut.footer.value = sum;
            this.summaryOut.footer.score = session.score;
          });
        });

        this.summaryOut.outcome = result.data.outcome;
        this.summaryOut.successCriteria = result.data.successCriteria;
      });
    },

    getPIPOptions() {
      this.$_getPIPOutcome().then((response) => {
        response.data.outcome.map((outcome) => {
          this.pipOptions.push({
            id: outcome.id,
            name: outcome.type,
          });
          return {};
        });
      });
    },

    receiveEngagement(value) {
      this.summaryStructure.orgId = this.$orgId;
      this.summaryStructure.actionId = value.actionId;
      this.summaryStructure.recommendation = value.remark;
      this.summaryStructure.others = value.others;

      if ((value.actionId !== "") && (value.remark !== "") && (value.others !== "")) {
        this.disableBtn = false;
      }
      else {
        this.disableBtn = true;
      }

      // eslint-disable-next-line no-console
      console.log(value);

    },

    setOutcomePayload(value) {
      this.summaryStructure.signatory.signatories = [];
      value.filter((item) => {
        if (item.signature !== "") {
          this.summaryStructure.signatory.signature = item.userId;
          this.summaryStructure.signatory.comments = item.comments;
          this.summaryStructure.signatory.date = item.date;

          const payload = {
            employeePipId: this.summaryStructure.employeePipId,
            signatoryId: item.userId,
            comments: item.comments,
            date: item.date,
          };

          this.$_signPIPCloseOut(payload)
            .then((response) => {
              this.$toasted.success(response.data.message, { duration: 5000 });
            })
            .catch((err) => {
              this.$toasted.error(err.response.data.message, {
                duration: 5000,
              });
            });
        }
        this.summaryStructure.signatory.signatories.push(item.userId);

        return {};
      });
    },

    setCloseout() {
      this.$_setCloseOut(this.summaryStructure)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getEmployeeCloseOut() {
      this.$_employeeCloseOut(this.$route.params.id).then((response) => {
        const info = response.data.data;

        info.closeout.filter((data) => {
          if (data.employeePipId === this.summaryStructure.employeePipId) {
            this.commentSection.commentData.comment = data.recommendation;
            this.commentSection.commentData.actionId = data.action.id;
            this.commentSection.commentData.others = data.others;
            this.commentSection.commentData.id = data.id;
          }
          return {};
        });

        info.signatures.filter((data) => {
          if (data.employeePipId === this.summaryStructure.employeePipId) {
            this.pipSignatureDetails.push(data);
          }
          return {};
        });

        this.loading = false;
      });
    },
  },

  mounted() {
    this.getEmployeePerformance();
    this.getEmployeePIP();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getEmployeeSummary();
    this.getPIPOptions();
    this.getPIPSignatures();
    this.getEmployeeCloseOut();
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>

<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1
          class="font-bold text-lg leading-6 tracking-0015 text-flame"
          v-if="header"
        >
          {{ header }}
        </h1>
        <h2
          class="font-bold text-lg leading-6 tracking-0015 text-darkPurple"
          v-if="title"
        >
          {{ title }}
        </h2>
      </div>
    </template>
    <template>
      <div
        class="w-full flex p-2 rounded-md bg-yellow-50 border border-yellow-500"
        v-if="message"
      >
        <div class="text-carrotOrange mx-auto self-center">
          <Icon size="xs" icon-name="icon-info" class="text-carrotOrange" />
        </div>
        <span class="flex text-sm mx-3">{{ message }}</span>
        <div class="text-carrotOrange mx-auto self-center">
          <Icon size="xs" icon-name="close" class="text-carrotOrange" />
        </div>
      </div>

      <div class="w-full my-2 flex" v-if="isFurtherAction">
        <CSelect
          :options="setOptions"
          label="What further action is recommended?"
          class="w-2/5 mr-4"
          v-model="data.actionId"
          @input="setPayload(data)"
        />

        <CText
          placeholder="Enter"
          label="If Others, Specify"
          class="flex flex-grow"
          v-model="data.others"
          @input="setPayload(data)"
        />
      </div>

      <div class="flex flex-col justify-start items-start gap-2">
        <h3 class="font-normal text-sm leading-5 text-jet">
          Remark/Comment From Manager.
        </h3>
        <Textarea
          placeholder="--Comment from Line Manager--"
          v-model="data.comment"
          @input="setPayload(data)"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CSelect from '@scelloo/cloudenly-ui/src/components/select';
import CText from '@scelloo/cloudenly-ui/src/components/text';

export default {
  name: "ESSEmployeeEngagement",
  components: {
    Card,
    Textarea,
    CSelect,
    CText,
  },
  props: {
    message: {
      type: String,
    },
    header: {
      type: String,
      required: true,
      default: "",
    },
    title: {
      type: String,
      required: true,
      default: "",
    },

    isFurtherAction: {
      type: Boolean,
      required: true,
      default: false,
    },

    setOptions: {
      type: Array,
      required: true,
      default: () => [],
    },

    data: {
      type: Object,
      required: true,
      default: () => {},
    },

  },

  data() {
    return {
      payload: {
        id: "",
        remark: "",
        actionId: "",
        others: "",
      },
    };
  },

  methods: {
    setPayload(data) {
      this.payload.remark = data.comment;
      this.payload.actionId = data.actionId;
      this.payload.others = data.others;
      this.payload.id = data.id;

      const payloadStructure = this.payload;

      if (!this.isFurtherAction) {
        delete payloadStructure.actionId;
        delete payloadStructure.others;

        if (payloadStructure.id === "") {
          delete payloadStructure.id;
        }
      }
      else {
          delete payloadStructure.id;
      }

      this.$emit("set", payloadStructure);
    },
  },

  mounted() {
    this.setPayload();
  },
};
</script>
<style lang="scss">
.tracking-0015 {
  letter-spacing: 0.015em;
}
</style>

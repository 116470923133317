<template>
  <div :class="className">
    <slot
      v-if="header"
      name="header"
      :header="{
        title: title,
        subtitle: subtitle
      }"
    />
    <table>
      <thead>
        <tr>
          <th v-for="(headerItem, i) in headers" :key="i" :style="`width:${headerItem.width}%;`">
            <div class="head" v-if="!headerItem.image">
              {{ headerItem.title }}
            </div>
          </th>
        </tr>
        <slot
          name="subheaders"
          :subheaders="{ subheaders }"
        />
      </thead>
      <tbody>
        <tr v-for="(item, i) in items" :key="i">
          <td v-for="(value, key) of populateTable(item)" :key="key">
            <slot name="item" :item="{ [key]: key, data: item, index: i }">
              {{ value }}
            </slot>
          </td>
        </tr>
        <tr class="tfooter" v-if="footer">
          <td :Colspan="`${colspan}`">
            <slot
              name="footer"
              :footer="{ ...footer }"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import VTooltip from "v-tooltip";

  export default {
    name: "SummaryTable",
    props: {
      className: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      headers: {
        type: Array,
        default: (() => [])
      },
      items: {
        type: Array,
        default: (() => [])
      },
      footer: {
        type: Object,
        default: (() => {})
      },
      header: {
        type: Object,
        default: (() => {})
      },
      subheaders: {
        type: Object,
        default: (() => {})
      },
      colspan: {
        type: Number,
        default: 3
      },
    },
    directives: {
      "v-tooltip": VTooltip
    },
    methods: {
      populateTable(data) {
        /* eslint-disable no-restricted-syntax */
          const element = {}
        this.headers.forEach((item) => {
          for (const key in data) {
            if (key === item.value) element[key] = data[key];
          }
        })
        return element;
      },
    },
  };
</script>

<style scoped lang="scss">
  .head {
    display: flex;
    cursor: pointer;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  tbody {
    box-shadow: 0px 1px 3px 0px #0807081a;
    border-radius: 5px;
    tr {
      border-bottom: 1px dashed #878E99 !important;
      background: #FFFFFF !important;
    }
    tr:last-child {
      border-bottom: 0 !important;
    }
    tr:nth-last-child(-n+2) {
      border-bottom: 0 !important;
    }
  }
  td, th {
    border: none;
    text-align: left;
    // padding: 10px;
    min-height: 60px !important;
  }
  th:first-child {
    padding: 10px !important;
  }
  td {
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #321c3b;
  }
  th {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #878e99;
  }
  .tfooter {
    background: #F7F7FF !important;
  }
  .text-10px {
    font-size: 10px;
  }
</style>
